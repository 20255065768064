import React, { useEffect, useContext, useRef, useState } from 'react';
import ReactToPrint from 'react-to-print';
// import { Br, Cut, Line, Printer, Text, Row, render } from 'react-thermal-printer';
import { BrowserRouter as Router, Switch, Route, Redirect, useHistory, useParams } from 'react-router-dom';
// import { fetch_order_information } from '../../../API/API';
import { useMutation, useQuery, useQueryClient, useInfiniteQuery } from 'react-query';
import CircularProgress from 'react-cssfx-loading/lib/CircularProgress';
import { LanguageContext } from '../../../LanguageContext';
import { Contexthandlerscontext } from '../../../Contexthandlerscontext.js';
import { serverbaselink, imagebaselink } from '../../../Env_Variables';
import noimage from '../Generalfiles/images/noimage.png';
import { Loggedincontext } from '../../../Loggedincontext';
import { Orderscontext } from './Orderscontext';

// export const ComponentToPrint = React.forwardRef((props, ref) => {
//     return <div ref={ref}>My cool content here!</div>;
// });
const ReceiptPrintcomponent = React.forwardRef((props, ref) => {
    const { lang, langdetect } = useContext(LanguageContext);
    const { fetchuseauthorizationQueryContext } = useContext(Contexthandlerscontext);
    const [orderpayload, setorderpayload] = useState({});

    const { setisloggedincontext, userloggedinfobjcontext } = React.useContext(Loggedincontext);
    const ordersummaryupdatercontext = (temporderpayload_context) => {
        var totalitemsprice = 0;
        var discountfees = 0;
        var totalafterdiscount = 0;
        var zoneprice = 0;
        var totalordersummary = 0;
        var poinstused = 0;
        if (temporderpayload_context?.zonename != null && temporderpayload_context?.zonename.length != 0) {
            zoneprice = temporderpayload_context?.zoneprice;
        }
        temporderpayload_context?.orderitems?.forEach(function (arrayItem) {
            var totalpriceeachitem = arrayItem.quantity * arrayItem.finalprice;
            totalitemsprice = totalitemsprice + totalpriceeachitem;
        });
        if (temporderpayload_context?.haspromocode == 1) {
           // if (temporderpayload_context?.promocodevaluetype == 'percentage') {
            //     discountfees = parseFloat(totalitemsprice) * (parseFloat(temporderpayload_context?.promocodediscount) / 100);
            // } else if (temporderpayload_context?.promocodevaluetype == 'value') {
            //     discountfees = temporderpayload_context?.promocodediscount;
            // }
            discountfees = temporderpayload_context?.promocodediscountfees;
        }
        if (temporderpayload_context?.usewalletpoints == 1) {
            poinstused = parseFloat(temporderpayload_context?.walletpointsused);
        }
        totalafterdiscount = totalitemsprice - poinstused - discountfees;
        totalordersummary = parseInt(totalafterdiscount) + parseInt(zoneprice) + parseFloat(temporderpayload_context?.orderextragenpayments?.totalvaluestobemodified);
        totalordersummary = totalordersummary?.toFixed(2);
        temporderpayload_context.totalitemsprice = totalitemsprice;
        temporderpayload_context.discountfees = discountfees;
        temporderpayload_context.totalafterdiscount = totalafterdiscount;
        temporderpayload_context.totalordersummary = totalordersummary;

        setorderpayload({ ...temporderpayload_context });
    };

    useEffect(() => {
        ordersummaryupdatercontext({ ...props?.orderitem });
    }, [props?.orderitem]);
    return (
        <div style={{ width: '100%', height: 'fit-content', color: 'black', fontWeight: 500, direction: langdetect == 'en' ? 'ltr' : 'rtl' }} class={langdetect == 'en' ? 'p-0' : 'pr-2'} ref={ref}>
            <div class={' col-lg-12 p-0 mb-0 d-flex align-items-center justify-content-center align-items-sm-center  '}>
                <div class="row m-0 w-100">
                    <div class="col-lg-12 allcentered p-0">
                        <div style={{ width: '350px', height: '125px' }}>
                            {userloggedinfobjcontext?.currentinstinfo?.instlogo != '' && (
                                <img
                                    src={imagebaselink + userloggedinfobjcontext?.currentinstinfo?.instlogo}
                                    style={{ objectFit: 'contain', width: '100%', height: '100%' }}
                                    class=""
                                    alt="Store Logo"
                                />
                            )}
                            {userloggedinfobjcontext?.currentinstinfo?.instlogo == '' && (
                                <img src={noimage} style={{ objectFit: 'contain', width: '100%', height: '100%' }} class="" alt="Store Logo" />
                            )}
                        </div>
                    </div>
                </div>
            </div>

            <div class={' col-lg-12 p-0 pl-2 pr-2 d-flex align-items-center justify-content-center align-items-sm-center '}>
                <div class={' col-lg-12 p-0 mb-0 '}>
                    {/* <p class="p-0 m-0" style={{ alignItems: 'center', alignSelf: 'center', textAlign: 'center', fontSize: '15px' }}>
                        Thanks
                    </p> */}
                    {/* <div class="col-lg-12 p-0" style={{ borderBottomWidth: 1, borderBottomStyle: 'dashed' }}></div> */}
                    {/* <p style={{ alignItems: 'center', alignSelf: 'center', textAlign: 'center' }}>IN STORE DOCUMENT</p> */}

                    <div class="col-lg-11" style={{ fontSize: '12px' }}>
                        <div class="row w-100 p-0">
                            <p class="m-0 p-0"> {userloggedinfobjcontext?.userinfo?.id}</p>
                        </div>
                    </div>
                    <div class="col-lg-11" style={{ fontSize: '12px' }}>
                        <div class="row w-100 p-0" style={{ justifyContent: 'space-between' }}>
                            <p class="m-0 p-0">{orderpayload?.timestamp}</p>
                            <p class="m-0 p-0">{fetchuseauthorizationQueryContext?.data?.data?.userinfo?.id}</p>
                        </div>
                    </div>
                    <div class="col-lg-11" style={{ fontSize: '12px' }}>
                        <div class="row w-100 p-0" style={{ justifyContent: 'space-between' }}>
                            <p class="m-0 p-0">{lang.ordernumber}: </p>
                            <p class="m-0 p-0">#{orderpayload?.orderid}</p>
                        </div>
                    </div>
                    <div class="col-lg-11" style={{ fontSize: '12px' }}>
                        <div class="row w-100 p-0" style={{ justifyContent: 'space-between' }}>
                            <p class="m-0 p-0">
                                {orderpayload?.customername} - {orderpayload?.customerphonenumber}
                            </p>
                        </div>
                    </div>
                    <div class="col-lg-11 mb-1" style={{ fontSize: '12px' }}>
                        <div class="row w-100 p-0 d-flex justify-content-start">
                            <p class={langdetect == 'en' ? 'm-0 p-0 text-left' : 'm-0 p-0 text-right'}>{orderpayload?.address}</p>
                        </div>
                    </div>
                    {orderpayload?.owner_notes && (
                        <div class="col-lg-11 mb-1" style={{ fontSize: '12px' }}>
                            <div class="row w-100 p-0" style={{ justifyContent: 'space-between' }}>
                                <p class={langdetect == 'en' ? 'm-0 p-0 text-left' : 'm-0 p-0 text-right'} dangerouslySetInnerHTML={{ __html: orderpayload?.owner_notes }}></p>
                            </div>
                        </div>
                    )}
                </div>
            </div>
            {/* <div class="col-lg-12 p-0 mb-3" style={{ borderBottomWidth: 1, borderBottomStyle: 'dashed' }}></div> */}

            <div class="col-lg-12 col-md-12 col-sm-12 p-0  pl-2 pr-2" style={{ fontSize: '11px' }}>
                <div class="row m-0 w-100 d-flex align-items-center ">
                    <p style={{ width: '34%' }} className={langdetect == 'en' ? 'p-0 m-0 text-left ' : 'p-0 m-0 text-right  '}>
                        {langdetect == 'en' ? 'TSP' : 'المنتج'}
                    </p>
                    <p style={{ width: '10%' }} className={langdetect == 'en' ? ' p-0 m-0 text-left text-uppercase  ' : ' p-0 m-0 text-right text-uppercase  '}>
                        {lang.quantity}
                    </p>
                    <p style={{ width: '25%' }} className=" p-0 m-0 text-center text-uppercase  ">
                        {lang.price}
                    </p>
                    <p style={{ width: '27%' }} className=" p-0 m-0 text-center text-uppercase  ">
                        {lang.amount}
                    </p>
                </div>
                <div class="col-lg-12 p-0 mb-1 mt-1" style={{ borderBottomWidth: 1, borderBottomStyle: 'dashed' }}></div>

                {orderpayload?.orderitems?.map((item, index) => {
                    var variantoptions = JSON.parse(item.variantoptions);
                    return (
                        <div class="col-lg-12 p-0">
                            <div
                                class="row m-0 w-100 d-flex align-items-center"
                                style={{
                                    maxHeight: '60px',
                                    color: '#141414',
                                    width: '100%',
                                    fontSize: '12px',
                                }}
                            >
                                <p
                                    style={{
                                        width: '34%',
                                        display: '-webkit-box',
                                        WebkitBoxOrient: 'vertical',
                                        WebkitLineClamp: 2,
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        whiteSpace: 'normal',
                                    }}
                                    className={langdetect == 'en' ? 'p-0 m-0 text-left' : 'p-0 m-0 text-right'}
                                >
                                    {langdetect == 'en' ? item?.productname_en ?? item?.productinfo?.name_en : item.productname_ar ?? item?.productinfo?.name_ar}
                                </p>

                                {/* <p style={{ width: '13%', wordBreak: 'break-word' }} className={'p-0 m-0 text-left '}></p> */}

                                <p style={{ width: '10%' }} className={langdetect == 'en' ? 'p-0 m-0 text-left ' : 'p-0 m-0 text-right '}>
                                    {item.quantity}
                                </p>

                                <p style={{ width: '25%' }} className={'p-0 m-0 text-center '}>
                                    {item.finalprice}
                                </p>

                                <p style={{ width: '27%' }} className={'p-0 m-0 text-center '}>
                                    {parseFloat(item.finalprice * item.quantity).toFixed(2)} {orderpayload.currencyname_en}
                                </p>
                                <div class="col-lg-12 p-0 mb-1 mt-1"></div>
                            </div>
                            {/* <div class="d-flex justify-content-start" style={{ width: '100%' }}>
                            </div> */}
                            {variantoptions != undefined && variantoptions != null && Array.isArray(variantoptions) && variantoptions.length != 0 && (
                                <div class="d-flex justify-content-start" style={{ width: '100%' }}>
                                    {variantoptions?.map(function (variopitem) {
                                        return (
                                            <p style={{ marginInlineEnd: '5px' }}>
                                                {/* {variopitem?.optionname}: */}
                                                <span>
                                                    {variopitem?.optionvalue} {variopitem?.valuename}{' '}
                                                </span>
                                            </p>
                                        );
                                    })}
                                </div>
                            )}
                        </div>
                    );
                })}
            </div>
            <div class="col-lg-12 col-md-12 col-sm-12 p-0  pl-2 pr-2" style={{}}>
                <div class="row m-0 d-flex justify-content-center w-100">
                    <div style={{ width: '100%', paddingTop: '10px' }}>
                        <div class="row m-0 w-100">
                            {/* {orderpayload?.offername != null && orderpayload?.offername != undefined && orderpayload?.offername?.length != 0 && (
                                <>
                                    <div class={' col-lg-6 col-md-6 col-sm-6 p-0 d-flex align-items-center justify-content-start  '}>
                                        <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                            Discount:
                                        </p>
                                    </div>
                                    <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end  '}>
                                        <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                            - {orderpayload?.discountprice} EGP
                                        </p>
                                    </div>
                                </>
                            )} */}

                            <div class="col-lg-11 p-0 mb-1">
                                <div class="row m-0 w-100 d-flex justify-content-end">
                                    <div class="col-lg-12 p-0" style={{ borderBottomWidth: 1, borderBottomStyle: 'dashed' }}></div>
                                </div>
                            </div>

                            <div class="col-lg-12 p-0">
                                <div class={langdetect == 'en' ? ' row m-0 w-100 p-1 pl-2 pr-0' : ' row m-0 w-100 p-1 pr-2 pl-0'}>
                                    <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                        <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                            {lang.subtotal}
                                        </p>
                                    </div>

                                    <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                        <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                            {parseFloat(orderpayload?.totalitemsprice).toFixed(2)} {orderpayload.currencyname_en}
                                        </p>
                                    </div>
                                    {orderpayload?.orderextragenpayments?.arrayofgenorderpayment?.map(function (itemgenpayment, index) {
                                        return (
                                            <>
                                                <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                                    <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                        {langdetect == 'en' ? itemgenpayment?.title_en : itemgenpayment?.title_ar}
                                                    </p>
                                                </div>

                                                <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                                    <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                        {itemgenpayment?.valuetype == 'value' && <>{parseFloat(itemgenpayment.value).toFixed(2)}</>}
                                                        <span>+</span>
                                                        {itemgenpayment?.valuetype != 'value' && (
                                                            <>
                                                                {parseFloat(itemgenpayment.value).toFixed(2)} %,{parseFloat(itemgenpayment.valuetobemodified).toFixed(2)}{' '}
                                                            </>
                                                        )}
                                                    </p>
                                                </div>
                                            </>
                                        );
                                    })}
                                    {orderpayload?.usewalletpoints == 1 && (
                                        <>
                                            <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    Wallet used
                                                </p>
                                            </div>

                                            <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    - {parseFloat(orderpayload?.walletpointsused)} {orderpayload.currencyname_en}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                    {orderpayload?.haspromocode == 1 && (
                                        <>
                                            <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    {lang.discount}
                                                </p>
                                            </div>

                                            <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    -{orderpayload?.discountfees} {orderpayload.currencyname_en}{' '}
                                                    {orderpayload?.promocodevaluetype == 'percentage' ? orderpayload?.promocodediscount + '%' : ''}
                                                </p>
                                            </div>

                                            <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    {lang.totalafterdiscount}
                                                </p>
                                            </div>

                                            <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    {orderpayload?.totalafterdiscount} {orderpayload.currencyname_en}
                                                </p>
                                            </div>
                                        </>
                                    )}
                                    {parseInt(orderpayload?.zoneprice) > 0 && (
                                        <>
                                            <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    {lang.shippingrate}
                                                </p>
                                            </div>

                                            <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                                <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                                    {parseFloat(orderpayload?.zoneprice).toFixed(2)} {orderpayload.currencyname_en}
                                                </p>
                                            </div>
                                        </>
                                    )}

                                    <div class={'col-lg-5 col-md-5  col-sm-5 p-0 d-flex align-items-center justify-content-start mt-2 '}>
                                        <p class="p-0 m-0 text-uppercase" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                            {lang.total}
                                        </p>
                                    </div>

                                    <div class={' col-lg-6 col-md-6  col-sm-6 p-0 d-flex align-items-center justify-content-end mt-2 '}>
                                        <p class="p-0 m-0" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                            {parseFloat(orderpayload?.totalordersummary).toFixed(2)} {orderpayload.currencyname_en}
                                        </p>
                                    </div>
                                </div>
                            </div>

                            <div class={' col-lg-12  p-0 d-flex align-items-center justify-content-center mt-3 '}>
                                <div class={' col-lg-12  p-0 d-flex align-items-center justify-content-center  '}>
                                    <p class="p-0 m-0 text-center" style={{ fontSize: '12px', color: 'var(--primary)' }}>
                                        [*] {lang.thisreceiptisrequiredforanexchangeorrefundtransaction}.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
});

export default ReceiptPrintcomponent;
